/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.comeos-menu-zone-right {
  float: right;
}
.altai-theme-content #bodyView .edit-mode #comeos-home-menu {
  position: relative!important;
}
.altai-theme-content #bodyView .edit-mode .comeos-mobile-menu,
.altai-theme-content #bodyView .edit-mode .comeos-menu-desktop {
  position: relative;
  display: block!important;
}
@media screen and (max-width: 1100px) {
  .comeos-menu-zone {
    height: 45px;
  }
  .altai-theme-content #bodyView .comeos-root-container.loggedin.adminmenu {
    margin-top: 94px;
  }
  .altai-theme-content #bodyView .comeos-root-container {
    margin-top: 50px;
  }
  .mobile-toggle {
    display: inline-block;
    float: right;
  }
  .mobile-toggle .mC {
    margin: -5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    display: block;
    font-size: 8px;
    background-color: black;
    color: white;
  }
  .mobile-toggle .mC .c {
    font-size: 10px;
  }
  .mobile-logo {
    display: inline-block;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
  .altai-theme-content #bodyView .mobile-logo img {
    max-height: 30px;
    margin-bottom: 0px;
  }
  .comeos-mobile-menu {
    display: block !important;
  }
  .comeos-mobile-menu .searcher form {
    width: 100%;
  }
  .comeos-mobile-menu .searcher form input {
    background-color: #EDEDED !important;
    width: 100%;
  }
  .comeos-mobile-menu.comeos-mobile-visible {
    display: block !important;
  }
}
@media screen and (min-width: 1101px) {
  .mobile-toggle {
    display: none;
  }
  .comeos-mobile-menu {
    display: none !important;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1150px) {
  .altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (min-width: 1151px) and (max-width: 1350px) {
  .altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (min-width: 1351px) and (max-width: 1600px) {
  .altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1801px) {
  .altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.altai-theme-content #bodyView .comeos-menu {
  background-color: white;
}
.altai-theme-content #bodyView .comeos-menu .altai-spinner-outer {
  position: absolute;
  top: 20px;
  right: 10px;
}
.altai-theme-content #bodyView .comeos-menu .altai-spinner {
  border: 2px solid #000;
  border-top-color: #D0D0D0;
  border-right-color: #D0D0D0;
  border-bottom-color: #D0D0D0;
}
.altai-theme-content #bodyView .comeos-menu .comeos-header-small {
  display: none;
}
.altai-theme-content #bodyView .comeos-menu .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a {
  color: #444;
  cursor: pointer;
  padding: 5px 0.8em;
  font-weight: 600;
  font-size: 1.3em;
  letter-spacing: 0;
}
.altai-theme-content #bodyView .comeos-menu .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a.language-selector {
  padding: 5px 0 0 0;
  display: inline-block;
}
.altai-theme-content #bodyView .comeos-menu .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a.language-selector.selected {
  border-bottom: 2px solid #444;
}
.altai-theme-content #bodyView .comeos-menu.transparent {
  position: absolute;
  padding-top: 10px;
  padding-left: 60px;
  background-color: transparent;
  z-index: 1;
  width: 100%;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header .header-top .header-top-menu ul li.userinfo {
  color: white;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header .header-top .header-top-menu ul li.userinfo a {
  color: white;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small {
  display: block;
  height: 70px;
  background-color: transparent;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li {
  color: white;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li.userinfo {
  color: white !important;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a {
  color: white;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a.language-selector {
  padding: 5px 0 0 0;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .comeos-menu-zone .nav-bar ul.menu li a.language-selector.selected {
  border-bottom: 2px solid white;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .header-top .header-top-logo {
  width: auto;
}
.altai-theme-content #bodyView .comeos-menu.transparent .comeos-header-small .header-top .header-top-logo img {
  height: auto;
}
.altai-theme-content #bodyView .comeos-menu-zone {
  display: inline-block;
}
.altai-theme-content #bodyView .topmenuvisible .comeos-menu .comeos-header-small {
  display: block;
}
.altai-theme-content #bodyView #comeos-home-menu {
  position: fixed;
}
.altai-theme-content #bodyView #comeos-home-menu .hidden {
  display: none;
}
.altai-theme-content #bodyView .comeos-menu.fixed {
  position: fixed;
  left: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
}
.altai-theme-content #bodyView .comeos-menu.fixed .comeos-header-small {
  display: block;
}
.altai-theme-content #bodyView .comeos-menu.fixed.midmenu {
  top: 0;
  /*top:@topmenuheight;*/
}
.altai-theme-content #bodyView .comeos-menu.fixed.topmenu {
  top: 0;
}
.altai-theme-content #bodyView .edit-mode .comeos-menu.fixed {
  position: relative;
}
.altai-theme-content #bodyView .loggedin .comeos-menu.fixed {
  position: fixed;
  z-index: 100;
  background-color: white;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
}
.altai-theme-content #bodyView .loggedin.adminmenu .comeos-menu.fixed {
  top: 44px;
}
.altai-theme-content #bodyView .loggedin.adminmenu .comeos-menu-mobile {
  max-height: calc(100vh - 94px);
}
.altai-theme-content #bodyView .loggedin.adminmenu .comeos-mobile-menu {
  top: 44px;
}
.altai-theme-content #bodyView .comeos-mobile-menu {
  transform: translate3d(0px, 0px, 0px);
  position: fixed;
  width: 100%;
  background-color: white;
  top: 0;
  display: none;
  z-index: 99;
}
.altai-theme-content #bodyView .comeos-mobile-menu .altai-spinner {
  border: 2px solid #000;
  border-top-color: #D0D0D0;
  border-right-color: #D0D0D0;
  border-bottom-color: #D0D0D0;
}
.altai-theme-content #bodyView .comeos-mobile-menu .mobile-toggle {
  font-size: 20px;
  cursor: pointer;
  outline: none;
  height: 50px;
  padding: 15px;
}
.altai-theme-content #bodyView .comeos-mobile-menu .mobile-toggle:focus {
  outline: none;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile {
  width: 100%;
  display: block;
  margin-bottom: 0;
  overflow-y: auto;
  height: calc(100vh - 50px);
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li {
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #f3f3f3;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li .language {
  display: inline-block;
  background-color: #f3f3f3;
  padding: 0 5px;
  margin: 0 10px 0 0;
  border-radius: 10px;
  text-align: center;
  width: 35px;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li .subitem {
  border-top: none;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li .submenu-chevron {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  z-index: 999;
  height: 48px;
  padding: 0 15px;
  background-color: #f3f3f3;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li .submenu-chevron i {
  line-height: 48px;
  font-size: 130%;
}
.altai-theme-content #bodyView .comeos-mobile-menu .comeos-menu-mobile li:last-child {
  margin-bottom: 40px;
}
.altai-theme-content #bodyView .edit-mode .comeos-mobile-menu {
  display: block;
}
.altai-theme-content #bodyView .comeos-menu-desktop {
  height: 65px;
  overflow: hidden;
}
.altai-theme-content #bodyView .comeos-menu-zone {
  height: 65px;
  margin-top: 0;
  margin-left: 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar {
  padding-left: 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li {
  height: 65px;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li a {
  cursor: pointer;
  padding: 0 1em;
  font-weight: 600;
  font-size: 1.4em;
  letter-spacing: 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-search-bar {
  background-color: white;
  margin-top: 13px;
  min-width: 40px;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-search-bar .search-icon {
  position: absolute;
  font-size: 30px;
  right: 10px;
  top: 7px;
  color: #999;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-search-bar:hover input {
  display: block;
  width: 200px;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu {
  background: black;
  color: white;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 1.625em;
  position: absolute;
  width: 100%;
  display: none;
  left: 0;
  font-size: 15px;
  z-index: 100;
  padding-left: 50px;
  line-height: 1.5em;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .subitemmenurow {
  padding: 20px 0 20px 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .title {
  font-size: 1.3em;
  line-height: 1.1em;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .menu-sub-item {
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .mySelection {
  color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .menu-sub-item:hover {
  text-decoration: none;
  color: #c3c3c3;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .comeos-subitem-description {
  border-right: 2px solid white;
  padding: 15px 40px 0 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .comeos-subitem-description .title {
  margin-bottom: 15px;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li .comeos-sub-menu .comeos-subitem-description p {
  line-height: normal;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li a.hover {
  display: inline-block;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.hover {
  background-color: #f3f3f3;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li a.hover + .comeos-sub-menu,
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu .comeos-sub-menu:hover {
  display: block;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-menu-search {
  font-size: 15px;
  color: black;
  position: relative;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-menu-search .searcher {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0;
  width: 300px;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-menu-search .searcher form {
  position: relative;
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-menu-search .searcher form input {
  background-color: #EDEDED;
  height: 100%;
  width: 100%;
  margin-top: 0px;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-menu-search .searcher form .search {
  position: absolute;
  top: 23px;
  right: 15px;
  cursor: pointer;
  color: #999;
}
.altai-theme-content #bodyView .comeos-menu-zone .nav-bar ul.menu li.comeos-divider {
  color: #CDCDCD;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 1100px) {
  .altai-theme-content #bodyView .topmenuvisible .comeos-menu .comeos-header-small,
  .altai-theme-content #bodyView .comeos-menu.fixed .comeos-header-small,
  .altai-theme-content #bodyView #comeos-home-menu,
  .altai-theme-content #bodyView .comeos-menu-desktop {
    display: none;
  }
  .mobilemenu {
    overflow: hidden;
  }
}
